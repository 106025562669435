.text-orange {
  color: $color-orange;
}

.text-light-gray {
  color: $color-gray-light;
}

.bg-gray {
  background-color: $color-gray-bg;
}

.bg-white {
  background-color: $color-white;
}
