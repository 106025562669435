.breadcrumbs-list {
  margin: 0;
  padding: 16px 0 16px 16px;
  font-size: 12px;
  line-height: 1.33;

  li.crumb {
    position: relative;
    display: inline-block;
    padding: 0 14px !important;

    .crumb-link {
      color: #bdbdbd;
      font-size: 12px;
      font-family: $nndagny-text-regular;
      line-height: 1.33;
    }

    &:after {
      display: none;
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: -10px;
      background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/crumb-chevron-gray.svg")
        no-repeat;
      background-size: cover;
    }

    &:first-of-type {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &:last-of-type {
      a {
        color: $color-orange;
        font-family: $nndagny-display-normal;
      }

      &:before {
        background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/crumb-chevron-orange.svg")
          no-repeat;
      }
    }

    &:not(:last-of-type) {
      &:hover {
        .crumb-link {
          color: $color-orange;
        }
      }
    }
  }
}
