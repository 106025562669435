.card-quote {
  position: relative;
  margin: 0;
  padding: 24px 48px;
  box-shadow: 0px 2px 8px 0px rgba(65, 65, 65, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(65, 65, 65, 0.10);
  border-radius: 4px;

  &:before, &:after {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/quote.svg");
    background-size: cover;
  }

  &:before {
    top: 30px;
    left: 24px;
    transform: rotate(180deg);
  }

  &:after {
    bottom: 24px;
    right: 24px;
  }

  .quote-author {
    margin-top: 16px;
    font-size: 10px;
    line-height: 1.2;
    text-transform: uppercase;
  }
}