.disclaimer {
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;

  @include breakpoint(large up) {
    align-items: center;
  }

  .icon-wrapper {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    background-color: $color-gray-bg;
    border-radius: 50%;

    img {
      width: 32px;
      max-width: 32px;
      max-height: 32px;
    }

    @include icon-colors;
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include bg-colors;

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-wrapper {
      margin-bottom: 12px;
    }

    p {
      text-align: center;
    }
  }
}
