.cards-two-columns {

  @include breakpoint(medium up) {
    display: flex;
    justify-content: space-between;
  }

  .card-wrapper {

    @include breakpoint(medium up) {
      width: calc(50% - 8px);
    }

    &:first-of-type {
      @include breakpoint(small only) {
        margin-bottom: 16px;
      }
    }
  }
}