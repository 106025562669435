.list-with-icons {

  .list-icon-item {

    @include breakpoint(large up) {
      display: flex;
      justify-content: flex-start;
    }

    .icon-wrapper {
      @include breakpoint(medium down) {
        margin-bottom: 24px;
      }

      @include breakpoint(large up) {
        margin-right: 24px;
      }

      img {

        @include breakpoint(medium down) {
          height: 48px;
          max-height: 48px;
        }

        @include breakpoint(large up) {
          width: 48px;
          max-width: 48px;
        }
      }
    }
  }

  &.two-cols {
    @include breakpoint(large up) {
      display: flex;
      flex-wrap: wrap;
      gap: 0 24px;
    }

    & > .list-item {
      @include breakpoint(large up) {   
        flex: 1 1 calc(50% - 12px);
      }
    }
  }
  

  &.alternately { 
    .list-icon-item {
      height: 100%;
      .content-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .list-item:nth-child(odd) {
      .list-icon-item {
        @include breakpoint(large up) {
          flex-direction: row-reverse;
        }

      }

      .icon-wrapper {
        @include breakpoint(large up) {
          margin: 0 0 0 1rem;
        }
      }

    }
  }

  .list-item {
    &.vertical {
      .list-icon-item {
        flex-direction: column;
        
        .icon-wrapper {
          margin-bottom: 16px;
    
          img {
            width: 32px;
            max-width: 32px;
          }
        }
      }
    }

    &.with-shadow {
      background-color: #fff;
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
      
      @include breakpoint(medium down) {
        padding: 16px;
      }

      @include breakpoint(large up) {
        padding: 24px;
      }
    }
  }

  .big-icon {
    
    .icon-wrapper {
      margin-right: 16px;

      img {
        width: 76px;
        max-width: 76px;
      }
    }
  }
}