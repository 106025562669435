.slider-icon {
  position: relative;

  &.without-shadow {
    .card-icon {
      box-shadow: none;
    }
  }
  
  .shadow {
    position: absolute;
    top: 0;
    bottom: 32px;
    z-index: 2;
    pointer-events: none;

    @include breakpoint(small only) {
      width: 10%;
    }

    @include breakpoint(medium up) {
      width: 10%;
    }

    &.shadow-left {
      opacity: 0;
      left: -2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 11%, rgba(255, 255, 255, 0) 83%);
    }

    &.shadow-right {
      right: -2px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 11%, rgba(255, 255, 255, 0) 83%);
    }
  }

  &.without-desktop-nav {
    .slider-nav  { 
      @include breakpoint(large) {
        display: none !important;
      }
      & ~ .shadow { 
        @include breakpoint(large) {
          display: none;
        }
      }
    }
  }

  ul {
    position: relative;
    margin-bottom: 24px;
  }

  .swiper-wrapper {

    .swiper-slide {
      height: auto;
    }
  }

  .slider-nav {
    justify-content: right;
    align-items: center;

    .slider-icon-nav {
      width: 24px;
      height: 24px;
      outline: 0;
      background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
      background-size: 7px 14px;
      background-position: center;
      background-repeat: no-repeat;

      &.slider-icon-prev {
        transform: rotate(180deg);
      }
    }

    .slider-pagination {
      margin: 0 4px;
      color: $color-grey-light;
      font-size: 16px;
      font-family: "NNNittiGrotesk-Heading";
      line-height: 1.25;

      .swiper-pagination-current {
        color: $color-grey-dark;
      }
    }
  }

  &:not(.swiper-container-initialized) {

    .shadow {
      display: none;
    }

    .swiper-wrapper {
      display: flex;
      justify-content: space-between;

      .swiper-slide {
        width: calc(50% - 8px);
      }
    }

    .slider-nav {
      display: none;
    }
  }

  &.swiper-container-initialized {
    overflow-x: hidden;

    .shadow {
      display: block;
    }

    .swiper-slide {
      max-width: 75%;
      cursor: grab;

      .card-icon {
        margin: 5px 5px 0;
      }

      @include breakpoint(medium up) {
        max-width: 50%;
      }

      @include breakpoint(large up) {
        max-width: 65%;
      }

      @include breakpoint(xxlarge up) {
        max-width: 45%;
      }
    }

    .slider-nav {
      display: flex;
    }
  }

  /* Scrollbar */
  .swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);

    .swiper-container-horizontal > & {
      position: absolute;
      left: 1%;
      bottom: 3px;
      z-index: 50;
      height: 5px;
      width: 98%;
    }
    
    .swiper-container-vertical > & {
      position: absolute;
      right: 3px;
      top: 1%;
      z-index: 50;
      width: 5px;
      height: 98%;
    }
  }

  .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: #EE7F00;
    border-radius: 10px;
    left: 0;
    top: 0;
  }

  .swiper-scrollbar-cursor-drag {
    cursor: move;
  }

  .swiper-scrollbar-lock {
    display: none;
  }
    
  .swiper-scrollbar {
    background: #E0E0E0;
    border-radius: 2px;
    height: 2px;
    flex-basis: 100%;
    bottom: 0;
    display: none;
    margin-right: 16px;
  }

  &.with-scrollbar {

    .slider-nav {
      z-index: 10;
      position: relative;
      padding-left: 128px;
    }
    .slider-pagination {
      display: none;
    }
    
    .swiper-scrollbar {
      display: block;
    }

    .slider-icon-nav {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      outline: 0;
      background-color: #404040;
      background-size: 9px 16px;
      background-position: center;
      background-repeat: no-repeat;  
      flex: 0 0 auto;

      &.swiper-button-disabled {
        background-color: #e0e0e0;
      }

      &.slider-icon-next {
        background-image: url("data:image/svg+xml;utf8,<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7.99999 7.99999L1 15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
        background-position: 55% 50%;

        &.swiper-button-disabled {
          background-image: url("data:image/svg+xml;utf8,<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7.99999 7.99999L1 15' stroke='%23bdbdbd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
          background-position: 55% 50%;
        }
      }

      &.slider-icon-prev {
        transform: none;
        background-image: url("data:image/svg+xml;utf8,<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.99999 1L1 7.99999L7.99999 15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
        margin-right: 8px;
        background-position: 47% 50%;
        
        &.swiper-button-disabled {
          background-image: url("data:image/svg+xml;utf8,<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.99999 1L1 7.99999L7.99999 15' stroke='%23bdbdbd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
          background-position: 47% 50%;
        }
      }
    }

    .shadow {
      &.shadow-left {
        background: linear-gradient(90deg, #f7f7f7 11%, rgba(255, 255, 255, 0) 83%);
      }
  
      &.shadow-right {
        background: linear-gradient(270deg, #f7f7f7 11%, rgba(255, 255, 255, 0) 83%);
      }
    }
  }
}