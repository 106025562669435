.icon-simple {

  .icon-svg {

    @include breakpoint(medium down) {
      height: var(--icon-size);
      max-height: var(--icon-size);
    }

    @include breakpoint(large up) {
      width: var(--icon-size);
      max-width: var(--icon-size);
    }
  }
}