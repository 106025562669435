.card-gift  {
  position: relative;
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(65, 65, 65, 0.1);
  border-radius: 6px;
  overflow: hidden;

  &:before {
    content: "";
    width: 80px;
    height: 20px;
    position: absolute;
    top: 30px;
    left: calc(50% - 40px);
    background-color: $color-white;
    border-radius: 20px;
    z-index: 2;
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: calc(50% - 10px);
    background-color: $color-white;
    border-radius: 20px;
    z-index: 2;
  }

  .card-top {
    height: 200px;
    position: relative;
    background-image: var(--card-gift-bg);
    background-size: cover;
    background-position: center;

    .icon-wrapper {
      position: absolute;
      left: calc(50% - 32px);
      bottom: -32px;
      width: 64px;
      height: 64px;
      min-width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $color-blue-500;

      img {
        width: 42px;
        max-width: 42px;
        max-height: 42px;
      }
    }
  }

  .card-bottom {
    position: relative;
    padding: 48px 24px 32px;
    text-align: center;

    //&:before {
    //  background-color: $color-white;
    //  content: "";
    //  position: absolute;
    //  top: 20px;
    //  left: -2px;
    //  width: 12px;
    //  height: 18px;
    //  border-bottom-left-radius: 0;
    //  border-bottom-right-radius: 24px 18px;
    //  border-top-left-radius: 0;
    //  border-top-right-radius: 24px 18px;
    //  box-shadow: inset 0px 0px 4px rgba(65, 65, 65, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(65, 65, 65, 0.1);
    //}

    [class*="section-header"] {
      margin-bottom: 32px;
    }
  }
}