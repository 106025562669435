.hero-two-columns {
  background-color: #F7F7F7;
  margin-bottom: 16px;

  @include breakpoint(large up) {
    width: calc(100% + 96px);
    margin-left: -48px;
  }

  & > .row {

    @media (min-width: 1200px) {
      max-width: 95.5rem;
    }

    @media (min-width: 1365px) {
      padding-right: 3.040625rem !important;
      padding-left: 3.040625rem !important;
    }

    @include breakpoint(large up) {
      background-image: var(--hero-bg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }

    @include breakpoint(large only) {
      height: 240px;
    }

    @include breakpoint(xlarge up) {
      height: 480px;
    }
  }

  .hero-image-mobile {

    @include breakpoint(medium down) {
      height: 240px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }

    @include breakpoint(medium only) {
      background-image: var(--hero-bg);
    }

    @include breakpoint(small down) {
      background-image: var(--hero-bg-mobile);
    }
  }
}

.hero-two-columns-masonry {

  .columns-container-masonry {

    @include breakpoint(large only) {
      transform: translateY(-48px);
    }

    .grid-item, .grid-sizer {

      @include breakpoint(medium down) {
        width: 100%;
      }

      @include breakpoint(large up) {
        width: calc(50% - 12px);
      }
    }

    .grid-item:nth-child(even) {

      @include breakpoint(large up) {
        transform: translateY(-160px);
      }
    }
  }
}





