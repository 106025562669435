.card-icon {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(65, 65, 65, 0.1);
  border-radius: 3px;
  
  &.larger-icon {
  
    .icon-wrapper {
      top: -18px;
      width: 72px;
      height: 72px;
      min-width: 72px;

      img {
        width: 42px;
        max-width: 42px;
        max-height: 42px;
      }

      .icon-symbol {
        font-size: 36px;
      }
    }

  }

  .icon-wrapper {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    background-color: $color-gray-bg;
    border-radius: 50%;

    img {
      width: 32px;
      max-width: 32px;
      max-height: 32px;
    }

    @include icon-colors;

    &.larger-icon {
  
      .icon-wrapper {
        top: -18px;
        width: 72px;
        height: 72px;
        min-width: 72px;
  
        img {
          width: 42px;
          max-width: 42px;
          max-height: 42px;
        }
  
        .icon-symbol {
          font-size: 36px;
        }
      }
  
    }
  }

  .card-bottom {
    min-height: unset;
    margin: 0;
    padding: 0;
  }

  @include bg-colors;

  &.highlight {
    border: 1px solid $color-orange-medium;
  }
  
  &.border-icon {
    box-shadow: 0px 10px 20px rgba(65, 65, 65, 0.1), 0px 3px 10px rgba(65, 65, 65, 0.1);
    margin-top: .5rem;

    .card-top {
      margin-top: -2.5rem;
    }    
  }

  &.no-shadow {
    box-shadow: none;
  }
  &.no-padding {
    padding: 0;
  }
  
  
}