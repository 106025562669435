.card-banner {
  box-shadow: 0px 2px 8px 0px rgba(65, 65, 65, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(65, 65, 65, 0.10);
  border-radius: 4px;

  @include breakpoint(medium up) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  .banner-photo {
    background-image: var(--banner-photo);
    background-size: cover;

    @include breakpoint(small down) {
      height: 300px;
      background-position: center right;
    }

    @include breakpoint(medium up) {
      min-width: 40%;
      width: 40%;
      background-position: center right;
    }

    @include breakpoint(large) {
      min-height: 100%;
    }
  }

  .banner-content {
    padding: 24px 24px;
  }
}