.documents-list {
  li {
    position: relative;
    display: block;

    &:before {
      width: 24px;
      height: 24px;
      content: "";
      position: absolute;
      top: 17px;
      left: 2px;
      background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/document-orange.svg");
      background-size: cover;
    }

    &:last-of-type {
      .document {
        border-bottom: 1px solid $color-gray-bg;
      }
    }

    .document {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      border: 0;
      border-top: 1px solid $color-gray-bg;
      padding: 18px 5px 18px 32px;
      font-size: 16px;
      font-family: $nngrotesk-text-heading;
      line-height: 1.25;

      .display-name {
        max-width: 80%;
        word-break: break-all;
      }

      .file-meta {
        color: $color-grey-light;
        font-family: $nngrotesk-text-regular;

        @include breakpoint(small down) {
          .file-type,
          .file-size {
            display: block;
            font-size: 13px;
          }
        }

        @include breakpoint(medium down) {
          min-width: 60px;
          text-align: right;
        }

        .file-type {
          display: inline-block;
          position: relative;
          padding-right: 10px;

          &:after {
            content: "";
            position: absolute;
            top: 8px;
            right: 0;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: $color-grey-light;

            @include breakpoint(small down) {
              top: 6px;
            }
          }
        }
      }
    }

    &.narrow {
      max-width: 800px;
    }
  }
}

.accordion {
  .documents-list {
    li {
      a:first-child {
        border-top: none;
      }
    }
    
    .document {
      .display-name {
        word-break: normal;
      }
    }
  }
}
