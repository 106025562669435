.side-photo {
  min-width: 100%;
  background-color: $color-white;
  border-radius: 3px;
  overflow: hidden;

  @include breakpoint(large up) {
    display: flex;
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section-content {
    width: 100%;

    @include breakpoint(small only) {
      padding: 24px;
    }

    @include breakpoint(medium up) {
      padding: 32px 48px;
    }

    @include breakpoint(large up) {
      width: 50%;
    }
  }

  .section-photo-mobile {
    width: 100%;
    height: 240px;
    background-image: var(--side-photo);
    background-size: cover;
    background-position: center;
  }

  .section-photo {
    width: 50%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .inner-photo {
      height: 100%;
      width: 100%;
      background-image: var(--side-photo);
      background-size: cover;
      background-position: center;
    }

    .side-legal {
      height: fit-content;
      padding: 16px 20px 0;
      background-color: $color-off-white;

      p {
        margin: 0;
      }
    }
  }

  &.small-photo {
    .section-content {
      width: 100%;
    }
    .section-photo {
      width: 25%;
      min-width: 320px;
    }
  }

  &.medium-photo {
    .section-content {
      width: 100%;
    }
    .section-photo {
      width: 33%;
      min-width: 420px;
    }
  }
}
