/* Accordion
--------------------------------------------------------------------------
========================================================================*/
/* Accordion default
------------------------------------------------------------------------*/
.accordion {
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  margin-bottom: 0;

  .accordion-item {
    position: relative;

    &:before {
      content: "";
      width: 14px;
      height: 20px;
      position: absolute;
      top: 19px;
      left: 5px;
      background: transparent url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-down.svg") center no-repeat;
      background-size: contain;
      z-index: 1;
      transition: 0.2s;
      pointer-events: none;
    }

    //&:first-child {
      .accordion-title {
        border-top: 1px solid $color-gray-bg;
      }
    //}

    &[data-item-hidden] {
      display: none;

      &.visible {
        display: block;
      }
    }

    &.expanded {

      &:before {
        transform: rotate(180deg);
      }

      & > .accordion-content {
        display: block;
      }
    }

    & > .accordion-title {
      width: 100%;
      position: relative;
      display: block;
      padding: 18px 18px 18px 32px;
      color: $color-gray;
      border: 0;
      background: $color-white;
      border-bottom: 1px solid $color-gray-bg;
      font-size: 16px;
      font-family: $nndagny-display-normal;
      line-height: 1.25;
      text-decoration: none;
      text-align: left;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        color: $color-orange;
      }

      .accordion-trigger {
        height: 33px;
        top: 2px;
        position: absolute;
        width: 33px;
        right: 10px;
        @include breakpoint(560px up) {
          top: -8px;
        }
      }

      //#{$actions-types} {
      //  background: $light-gray;
      //}
    }

    .accordion-content {
      display: none;
      padding: 18px 18px 18px 40px;
      border: 0;
      //background: $light-gray;
      transition: height 0.4s, max-height 0.4s, padding-top 0.8s, padding-bottom 0.8s;
      overflow: hidden;

      &.accordion-subcontent {
        padding: 0 40px;

        .custom-checkbox {
          label {
            font-size: 13px;
          }

          input[type="checkbox"] {
            &:checked + label {
              font-family: $nndagny-display-normal;
            }

            & + label {
              &::before {
                top: 10px;
                border-color: $color-orange;
              }
            }
          }
        }
      }

      //a:not(.button):not(.arrow-link) {
      //  text-decoration: underline;
      //
      //  &:hover {
      //    text-decoration: none;
      //  }
      //}
      //
      //a:not(.arrow-link) {
      //  color: $color-orange;
      //  font-size: 16px;
      //  font-family: $nndagny-display-normal;
      //  line-height: 1.25;
      //  text-decoration: none;
      //
      //  &:hover,
      //  &:focus {
      //    color: $color-orange;
      //    text-decoration: underline;
      //  }
      //
      //  &:visited {
      //    color: $color-orange-dark !important;
      //  }
      //}

      &.slim-padding {
        @media #{$small-only} {
          padding: 5px;
        }
      }

      .bg-block {
        padding: 20px;
        background-color: $white;
        @media #{$small-only} {
          padding: 15px;
        }
      }
    }
  }
}
