.slider-icon-shift {
  position: relative;

  .shadow {
    position: absolute;
    top: 0;
    bottom: 32px;
    z-index: 2;
    pointer-events: none;

    @include breakpoint(small only) {
      width: 10%;
    }

    @include breakpoint(medium up) {
      width: 10%;
    }

    &.shadow-left {
      opacity: 0;
      left: -2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 11%, rgba(255, 255, 255, 0) 83%);
    }

    &.shadow-right {
      right: -2px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 11%, rgba(255, 255, 255, 0) 83%);
    }
  }

  ul {
    position: relative;
    margin-bottom: 24px;
  }

  .swiper-wrapper {

    .swiper-slide {
      height: auto;
    }
  }

  .slider-nav {
    justify-content: right;
    align-items: center;

    .slider-icon-shift-nav {
      width: 24px;
      height: 24px;
      outline: 0;
      background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
      background-size: 7px 14px;
      background-position: center;
      background-repeat: no-repeat;

      &.slider-icon-shift-prev {
        transform: rotate(180deg);
      }
    }

    .slider-pagination {
      margin: 0 4px;
      color: $color-grey-light;
      font-size: 16px;
      font-family: "NNNittiGrotesk-Heading";
      line-height: 1.25;

      .swiper-pagination-current {
        color: $color-grey-dark;
      }
    }
  }

  &:not(.swiper-container-initialized) {

    .shadow {
      display: none;
    }

    .slider-nav {
      display: none;
    }

    .swiper-wrapper {
      display: flex;
      gap: var(--gutter, 16px);

      .swiper-slide {
        flex-basis: 100%;
        flex-shrink: 1;
      }
    }
  }

  &.swiper-container-initialized {
    overflow-x: hidden;

    .shadow {
      display: block;
    }

    .swiper-slide {
      max-width: 75%;
      cursor: grab;

      //.card-icon-shift {
      //  margin: 5px 5px 0;
      //}

      @include breakpoint(medium up) {
        max-width: 50%;
      }

      @include breakpoint(large up) {
        max-width: 65%;
      }

      @include breakpoint(xxlarge up) {
        max-width: 45%;
      }
    }

    .slider-nav {
      display: flex;
    }
  }

  &.wide-slides {

    .swiper-slide {
      max-width: 85%;

      @include breakpoint(large up) {
        max-width: 75%;
      }

      @include breakpoint(xxlarge up) {
        max-width: 65%;
      }
    }
  }
}