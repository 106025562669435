@import "design-system/common/variables";

#e-client {

  // Foundation
  @import "design-system/_foundation/foundation";

  @include foundation-flex-grid;
  @include foundation-flex-classes;
  @include foundation-visibility-classes;

  // Variables
  @import "design-system/common/colors";

  // Common
  @import "design-system/common/base";
  @import "design-system/common/accessibility";
  @import "design-system/common/typography";
  @import "design-system/common/mixins";

  // Utilities
  @import "design-system/utilities/utilities";
  @import "design-system/utilities/general";

  // Swiper
  @import "../../../node_modules/swiper/swiper";

  // Components DS
  @import "design-system/components-ds/cards/card-banner";
  @import "design-system/components-ds/cards/card-gift";
  @import "design-system/components-ds/cards/card-icon";
  @import "design-system/components-ds/cards/card-icon-shift";
  @import "design-system/components-ds/cards/card-quote";
  @import "design-system/components-ds/cards/cards-two-columns";
  @import "design-system/components-ds/accordion";
  @import "design-system/components-ds/breadcrumbs";
  @import "design-system/components-ds/button";
  @import "design-system/components-ds/button-scroll";
  @import "design-system/components-ds/button-with-login";
  @import "design-system/components-ds/buttons-group";
  @import "design-system/components-ds/callout";
  @import "design-system/components-ds/container";
  @import "design-system/components-ds/container-two-columns";
  @import "design-system/components-ds/counter";
  @import "design-system/components-ds/disclaimer";
  @import "design-system/components-ds/documents";
  @import "design-system/components-ds/full-photo";
  @import "design-system/components-ds/gift-with-counter";
  @import "design-system/components-ds/header";
  @import "design-system/components-ds/hero";
  @import "design-system/components-ds/hero-two-columns";
  @import "design-system/components-ds/icon-simple";
  @import "design-system/components-ds/list-simple";
  @import "design-system/components-ds/list-with-icons";
  @import "design-system/components-ds/pagination";
  @import "design-system/components-ds/side-photo";
  @import "design-system/components-ds/slider-icon";
  @import "design-system/components-ds/slider-icon-shift";
  @import "design-system/components-ds/spacer";
  @import "design-system/components-ds/text-simple";

  background-color: $color-white;
  color: $color-grey-dark;
  font-family: $nndagny-text-regular;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  min-height: 85vh;
  min-width: 100%;

  display: flex;
  justify-content: space-between;

  .content-side {
    width: 100%;
    padding: 60px 0 32px;
    display: flex;
    align-items: center;

    @include breakpoint(medium up) {
      padding: 112px 0 32px;
    }

    @include breakpoint(large up) {
      width: 60%;
    }

    @include breakpoint(xxlarge up) {
      width: 40%;
    }
  }

  .photo-side {
    width: 40%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    background-image: var(--side-photo);

    @include breakpoint(xxlarge up) {
      width: 60%;
    }
  }

}

.eclient-logo-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;

  @media(max-width: 639px) {
    padding: 14px 16px;
  }

  @media(min-width: 640px) {
    padding: 16px 36px;
  }

  @media(min-width: 1024px) {
    padding: 16px 48px;
  }

  .logo-nn-lp {

    @media(max-width: 639px) {
      height: 24px;
      max-height: 24px;
    }

    @media(min-width: 640px) {
      height: 48px;
      max-height: 48px;
    }

    @media(min-width: 1024px) {

    }
  }

  .logo-luxmed {

    @media(max-width: 639px) {
      height: 20px;
      max-height: 20px;
    }

    @media(min-width: 640px) {
      height: 48px;
      max-height: 48px;
    }

    @media(min-width: 1024px) {
      position: absolute;
      top: 16px;
      right: calc(40% + 48px);
    }

    @media(min-width: 1601px) {
      right: calc(60% + 48px);
    }
  }
}

footer.footer-eclient {
  border-top: 2px solid #EE7F00;

  @media(max-width: 639px) {
    padding: 26px 16px 68px;
  }

  @media(min-width: 640px) {
    padding: 36px;
  }

  @media(min-width: 1024px) {
    padding: 36px 48px;
  }

  .footer-top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .logo {
      height: 34px;
      max-height: 34px;
    }
  }

  .footer-middle-block {

    @media(min-width: 1180px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .footer-links {
      margin: 0;

      @media(max-width: 1179px) {
        margin-bottom: 16px;
      }

      li {
        list-style-type: none;

        @media(max-width: 639px) {
          margin-bottom: 8px;
        }

        @media(min-width: 640px) {
          display: inline-block;
          margin-right: 16px;
        }

        a {
          font-size: 16px;
          line-height: 1.25;
        }
      }
    }

    .footer-contact {
      margin-bottom: 8px;
      color: #767676;
      font-size: 16px;
      line-height: 1.25;

      br {
        @media(min-width: 740px) {
          display: none;
        }
      }

      @media(min-width: 1180px) {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        margin: 0 12px;
        color: #767676;
        font-size: 16px;
        line-height: 1.25;

        &:nth-of-type(1) {
          @media(max-width: 739px) {
            margin-left: 0;
          }
        }

        &:nth-of-type(2) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: -17px;
            top: 5px;
            width: 8px;
            height: 8px;
            background-color: #EE7F00;
            border-radius: 8px;
          }
        }

        &:hover {
          color: #EE7F00;
        }
      }

    }

    .footer-legal {
      color: #767676;
      font-size: 12px;
      line-height: 1.33;
      margin: 0;

      @media(min-width: 1180px) {
        display: none;
      }
    }
  }

  .footer-bottom-block {
    margin-top: 16px;

    @media(min-width: 1180px) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }

    .footer-legal {
      color: #767676;
      font-size: 12px;
      line-height: 1.33;
      margin: 0;

      &:nth-of-type(2) {

        @media(max-width: 1179px) {
          display: none;
        }
      }
    }
  }
}



