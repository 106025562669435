/* BASE
--------------------------------------------------------------------------
========================================================================*/

*, *::before, *::after {
 box-sizing: border-box;
}

/* Document
------------------------------------------------------------------------*/

img[data-lazy-src] {
  width: 100%;
}

picture {
  img {
    display: block;
    width: 100%;
  }
}

body > img {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[hidden] {
  display: none !important;
}
