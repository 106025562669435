/* TYPOGRAPHY
--------------------------------------------------------------------------
========================================================================*/

small,
.text-small {
  font-size: 12px;
  line-height: 1.33;
}

b,
strong {
  font-family: $nndagny-text-regular;
  font-weight: 700;
}